<template>
  <div class="propertyType">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="收费员姓名">
                <el-autocomplete
                  v-model="formInline.pdaManagerName"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  @select="handleSelect"
                >
                </el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Belonging_operator')">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                  @change="getOperationInfo"
                >
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.On_duty_status')">
                <el-select
                  v-model.trim="formInline.pdaManagerState"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option label="在职" value="1"></el-option>
                  <el-option label="离职" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.date')" prop="showDate" class="time_item">
                <!--<el-select v-model.trim="formInline.showDate" filterable @change="changeDateType()" size="15" style="width:100px;">-->
                <el-select
                  v-model.trim="formInline.showDate"
                  filterable
                  size="15"
                  style="width: 72px !important"
                >
                  <el-option label="月" value="month">月</el-option>
                  <el-option label="年" value="year">年</el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="time_items">
                <el-date-picker
                  v-model="value2"
                  type="month"
                  :picker-options="pickerOptions"
                  placeholder="选择月"
                  class="custom-time-picker"
                  value-format="yyyy-MM"
                  :clearable="false"
                  v-show="formInline.showDate == 'month'"
                ></el-date-picker>
                <el-date-picker
                  v-model="value3"
                  type="year"
                  :picker-options="pickerOptions"
                  placeholder="选择年"
                  value-format="yyyy"
                  :clearable="false"
                  v-show="formInline.showDate == 'year'"
                ></el-date-picker>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center">
          </el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper" v-show="total != 0">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import autoComplete from '@/mycomponents/plete';
import { dateFormat } from "@/common/js/public";
export default {
  name: "assetTypes",
  components: {
    // autoComplete
  },
  data() {
    const newDate = new Date();
    newDate.setTime(newDate.getTime() - 3600 * 1000 * 24);

    const startTime = new Date();
    startTime.setTime(startTime.getTime() - 3600 * 1000 * 24);

    return {
      streetId: "",
      areaId: "",
      name: "",
      page: 1,
      pageSize: 10,
      total: 0,
      tenantList: [],
      assetsTypeList: [],
      reInline: {
        pdaManagerName: "",
        pdaManagerState: "",
        pdaManagerRole: "",
        parkId: "",
        equipmentId: "",
        account: "",
        operationId: "",
        areaId: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > newDate;
        },
      },
      tableCols: [
        {
          prop: "pdaManagerName",
          label: this.$t("list.toll_collector_name"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Belonging_operator"),
          width: "",
        },
        {
          prop: "pdaManagerState",
          label: this.$t("list.On_duty_status"),
          width: "",
        },
        {
          prop: "beCheckedReport",
          label: this.$t("list.Number_of_inspections"),
          width: "",
        },
      ],
      loading: false,
      tableData: [],
      value2: dateFormat(startTime, "yyyy-MM"),
      value3: dateFormat(startTime, "yyyy"),
      formInline: {
        pdaManagerName: "",
        showDate: "month",
        dataDate: "",
        operationId: "",
        pdaManagerState: "",
      },
      restaurants: [],
    };
  },
  methods: {
    // 所属运营商选择
    getOperationInfo(val) {
      this.formInline.operationId = val;
    },
    // 所属运营商下拉框
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    querySearchAsync(queryString, cb) {
      console.log(queryString);
      this.formInline.managerId = "";
      let restaurants = this.restaurants;
      let results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants;
      console.log("results-->", results);
      cb(results);
      // clearTimeout(this.timeout);
      // this.timeout = setTimeout(() => {
      //   cb(results);
      // }, 2000 * Math.random());
    },
    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      this.formInline.managerId = item.address;
    },
    receiveData(data) {
      this.formInline.assetsTypeName = data.assetsTypeName;
    },
    // 获取资产类型
    getAssetsTypeList() {
      this.$axios
        .get("/acb/2.0/assetsType/allList", {
          data: {
            type: 0,
          },
        })
        .then((res) => {
          this.assetsTypeList = res.value.list;
        });
    },
    clearFrom() {
      this.formInline = {};
    },
    handleCommand(cmd, data) {
      if (cmd === "a") {
        this.$router.push({
          path: "auditStatisticsDetail",
          query: data,
        });
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 获取收费员列表
    mangerList() {
      // this.restaurants = this.loadAll();
      let url = "/acb/2.0/pdaManager/query";
      this.$axios
        .get(url, {
          data: {
            page: 0,
            pageSize: 0,
            // dateType: this.formInline.showDate == 'month' ? 2 : 3,
            dataDate: this.formInline.time,
            // ...this.reInline,
            pdaManagerRole: 1,
          },
        })
        .then((res) => {
          let resList = res.value.list;
          if (res.state == 0) {
            resList.forEach((element) => {
              let obj = {};
              obj.address = element.pdaManagerId;
              obj.value = element.pdaManagerName;
              this.restaurants.push(obj);
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    searchData() {
      if (this.formInline.showDate == "month") {
        // this.formInline.time = dateFormat(this.value2, 'yyyy-MM');
        this.formInline.time = this.value2;
      } else if (this.formInline.showDate == "year") {
        // this.formInline.time = dateFormat(this.value3, 'yyyy');
        this.formInline.time = this.value3;
      }
      this.$axios
        .get("/acb/2.0/inspectionStatistics/directdata", {
          data: {
            pdaManagerName: this.formInline.pdaManagerName,
            pdaManagerState: this.formInline.pdaManagerState,
            operationId: this.formInline.operationId,
            dateType: this.formInline.showDate == "month" ? 2 : 3,
            dataDate: this.formInline.time,
            pageNo: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.tableData = [];
          this.tableData = res.value.list;
          this.total = res.value.total * 1 || 0;
        });
    },
  },
  activated() {
    //  this.searchData();
  },
  created() {
    this.getTenantList();
  },
  mounted() {
    this.mangerList();
    this.searchData();
    // this.getAssetsTypeList();
  },
};
</script>
<style></style>
<style lang="stylus" scoped>
::v-deep .el-date-editor.custom-time-picker {
  width: 72px;
}

::v-deep .el-form-item.time_item {
  min-width: 0;

  .el-input__inner {
    min-width: 72px;
  }
}

::v-deep .el-form-item.time_items {
  min-width: 0;

  .el-input__inner {
    min-width: 142px;
  }

  .el-input__prefix {
    left: 100px;
  }
}

.propertyType {
  .content {
    overflow: hidden;

    .pagerWrapper {
      text-align: right;
      margin-top: 18px;
      font-size: 12px;
    }
  }
}

::v-deep .el-select {
  width: 100% !important;
}
</style>
